//alias: Ragtime.Date
/** Создаем observable, который будет служить значением поля модели */
export function fieldValue() {
    const fieldValue = ko.observable(null);
    const result = ko.pureComputed({
        read: () => fieldValue(),
        write: (value) => {
            if (!value)
                fieldValue(null);
            else if (typeof (value) === "number")
                fieldValue(new Date(value));
            else if (value instanceof Date)
                fieldValue(value);
            else
                throw new Error("Bad value");
        }
    }).extend({ notify: 'always' });
    result(fieldValue());
    return result;
}
export function dateFromDto(dto) {
    if (!dto)
        return null;
    else
        return new Date(dto.year, dto.monthIndex, dto.date, dto.hours ?? 0, dto.minutes ?? 0, dto.seconds ?? 0, dto.millisecons ?? 0);
}
export function dtoFromDate(date) {
    if (!date)
        return null;
    else {
        return {
            year: date.getFullYear(),
            monthIndex: date.getMonth(),
            date: date.getDate(),
            hours: date.getHours(),
            minutes: date.getMinutes(),
            seconds: date.getSeconds(),
            millisecons: date.getMilliseconds(),
        };
    }
}
export function compare(a, b) {
    let dateA = dateFromDto(a);
    let dateB = dateFromDto(b);
    if ((dateA) && (dateB))
        return dateFromDto(a).getTime() - dateFromDto(b).getTime();
    else
        return 0;
}
